<!-- eslint-disable max-lines -->
<template>
  <b-container fluid class="mb-4">
    <LoadingSpinner :is-loaded="isLoaded">
      <template v-if="isLoaded">
        <b-row align-v="center" no-gutters class="mb-4">
          <b-col lg="8">
            <h2 :class="'text-' + headingVariant()">
              {{ round.contest.group }} {{ round.contest.name }} –
              <span class="text-nowrap">{{ round.name }}</span>
            </h2>

            <RoundSelectionDropdown
              :contests="contests"
              :rounds="rounds"
              view-name="evaluators"
              show-group
              :variant="headingVariant()"
            />
          </b-col>
          <b-col lg="4">
            <TimeProgress
              :start-time="new Date(round.start_time)"
              :end-time="new Date(round.end_time)"
              :now="now"
              class="mt-3"
            />
          </b-col>
        </b-row>

        <b-overlay :show="!isTableLoaded">
          <b-table-simple
            small
            bordered
            :responsive="true"
            class="evaluators-table mb-1"
          >
            <b-thead>
              <b-tr>
                <b-th />
                <b-th
                  v-for="category of presentCategories"
                  :key="`category-${category.id}`"
                  class="text-center"
                >
                  {{ category.name }}
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="problem of problems" :key="`problem-${problem.id}`">
                <b-th class="align-middle" style="max-width: 7em">
                  <div>
                    <b-row no-gutters align-v="center">
                      <b-col class="problem-name">
                        {{ problem.number }}. {{ problem.name }}
                      </b-col>
                      <b-col cols="auto">
                        <PlusMinusButton
                          :plus="userMissingCategories(problem).length !== 0"
                          class="ml-1"
                          @plus="addAllEvaluators(problem)"
                          @minus="removeAllEvaluators(problem)"
                        />
                      </b-col>
                    </b-row>
                  </div>
                </b-th>
                <b-td
                  v-for="category of presentCategories"
                  :key="`cell-${problem.id}-${category.id}`"
                  :class="[
                    'text-center align-bottom py-0',
                    isPresent(problem, category) ? '' : 'empty-cell',
                  ]"
                  :variant="cellVariant(problem.id, category.id)"
                >
                  <template v-if="isPresent(problem, category)">
                    <div class="evaluator-list row align-items-center">
                      <div class="col">
                        <div
                          v-for="user of evaluatorsOf(
                            problem.id,
                            category.id,
                          ).reverse()"
                          :key="`user-${user.id}`"
                        >
                          {{ user.full_name }}
                        </div>
                      </div>
                    </div>
                    <PlusMinusButton
                      :plus="!isUserEvaluatorOf(problem.id, category.id)"
                      @plus="addEvaluator(problem.id, category.id)"
                      @minus="removeEvaluator(problem.id, category.id)"
                    />
                  </template>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-overlay>

        <span class="text-muted small">
          Naposledy obnovené pred {{ secondsFromLastTableLoad }} sekundami.
          <b-link @click="loadEvaluators"> Obnoviť teraz. </b-link>
        </span>
      </template>
    </LoadingSpinner>
  </b-container>
</template>

<script>
import {
  apiContests,
  apiProblems,
  apiRounds,
  LoadingSpinner,
  RoundSelectionDropdown,
  TimeProgress,
} from "frontend-common";
import constants from "@/constants";
import PlusMinusButton from "@/components/PlusMinusButton.vue";

export default {
  name: "EvaluatorsDetail",
  components: {
    LoadingSpinner,
    PlusMinusButton,
    TimeProgress,
    RoundSelectionDropdown,
  },
  mixins: [apiContests, apiProblems, apiRounds],
  props: {
    roundId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoaded: false,
      isTableLoaded: true,
      allCategories: [],
      round: {},
      evaluators: [],
      now: new Date(),
      lastTableLoad: null,
      intervals: [],

      rounds: [],
      roundsLoaded: false,
      contests: [],
      contestsLoaded: false,
    };
  },
  computed: {
    problems() {
      return this.round.problems || [];
    },
    presentCategories() {
      return this.allCategories.filter(({ name }) =>
        this.problems.some((problem) =>
          problem.categories.map((c) => c.name).includes(name),
        ),
      );
    },
    userId() {
      return this.$root.state.user.id;
    },
    secondsFromLastTableLoad() {
      return Math.ceil((this.now - this.lastTableLoad) / 1000);
    },
  },
  watch: {
    roundId() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
    this.intervals = [
      setInterval(
        () => this.loadEvaluators(),
        constants.autoReloadIntervals.mid,
      ),
      setInterval(() => (this.now = new Date()), 1000),
    ];

    this.apiContests()
      .then((response) => (this.contests = response.reverse()))
      .finally(() => (this.contestsLoaded = true));

    this.apiRounds()
      .then((response) => (this.rounds = response))
      .finally(() => (this.roundsLoaded = true));
  },
  beforeDestroy() {
    for (const interval of this.intervals) clearInterval(interval);
  },
  methods: {
    loadData() {
      this.isLoaded = false;
      const promises = [
        this.apiCategories().then(
          (response) => (this.allCategories = response),
        ),
        this.apiRoundStatus(this.roundId).then(
          (response) => (this.round = response),
        ),
        this.loadEvaluators(),
      ];
      Promise.all(promises).finally(() => (this.isLoaded = true));
    },
    loadEvaluators() {
      return this.apiRoundEvaluators(this.roundId).then((response) => {
        this.evaluators = response;
        this.lastTableLoad = new Date();
      });
    },
    isPresent(problem, category) {
      if (problem.interactive) {
        return false;
      }
      return problem.categories.map((c) => c.name).includes(category.name);
    },
    evaluatorsOf(problemId, categoryId) {
      return this.evaluators
        .filter((e) => e.problem === problemId && e.category === categoryId)
        .map((e) => e.user);
    },
    isUserEvaluatorOf(problemId, categoryId) {
      return this.evaluatorsOf(problemId, categoryId)
        .map((user) => user.id)
        .includes(this.userId);
    },
    userMissingCategories(problem) {
      return problem.categories.filter(
        (c) => !this.isUserEvaluatorOf(problem.id, c.id),
      );
    },
    headingVariant() {
      switch (this.round.contest.group) {
        case constants.contestGroups.PIKOMAT:
          return "pmat-blue";
        case constants.contestGroups.PIKOFYZ:
          return "pmat-orange";
        case constants.contestGroups.TERABIO:
          return "pmat-green";
      }
      return "primary";
    },
    cellVariant(problemId, categoryId) {
      if (this.isUserEvaluatorOf(problemId, categoryId)) return "success";
      if (this.evaluatorsOf(problemId, categoryId).length === 0)
        return "warning";
      return "";
    },
    addEvaluator(problemId, categoryId) {
      this.executeAddEvaluators([
        this.apiPostProblemEvaluator(problemId, categoryId, this.userId),
      ]);
    },
    removeEvaluator(problemId, categoryId) {
      const evaluatorId = this.evaluators.find(
        (e) =>
          e.problem === problemId &&
          e.category === categoryId &&
          e.user.id === this.userId,
      ).id;
      this.executeRemoveEvaluators([this.apiDelProblemEvaluator(evaluatorId)]);
    },
    addAllEvaluators(problem) {
      const promises = [];
      for (const category of this.userMissingCategories(problem)) {
        promises.push(
          this.apiPostProblemEvaluator(problem.id, category.id, this.userId),
        );
      }
      this.executeAddEvaluators(promises);
    },
    removeAllEvaluators(problem) {
      const evaluatorIds = this.evaluators
        .filter((e) => e.problem === problem.id && e.user.id === this.userId)
        .map((e) => e.id);
      const promises = [];
      for (const evaluatorId of evaluatorIds) {
        promises.push(this.apiDelProblemEvaluator(evaluatorId));
      }
      this.executeRemoveEvaluators(promises);
    },
    executeAddEvaluators(promises) {
      this.isTableLoaded = false;
      Promise.all(promises)
        .then(() => this.$root.successToast("Budeš opravovať, super!"))
        .catch(() => this.$root.dangerToast("Pri prihlasovaní nastala chyba."))
        .then(this.loadEvaluators)
        .finally(() => (this.isTableLoaded = true));
    },
    executeRemoveEvaluators(promises) {
      this.isTableLoaded = false;
      Promise.all(promises)
        .then(() => this.$root.infoToast("Odhlásil/-a si sa z opravovania."))
        .catch(() => this.$root.dangerToast("Pri odhlasovaní nastala chyba."))
        .then(this.loadEvaluators)
        .finally(() => (this.isTableLoaded = true));
    },
  },
};
</script>

<style scoped>
.evaluators-table {
  line-height: 1.1em;
}
.evaluators-table >>> table {
  /* Table was a little bit scrollable so this is not the best one but simple fix */
  width: 99%;
}

.problem-name {
  max-height: 3.2em;
  overflow: hidden;
  font-size: 0.9em;
  line-height: 1.05em;
}

.empty-cell {
  background-color: var(--light);
}

.evaluator-list {
  min-height: 3em;
  font-size: 0.8em;
}
</style>
