<template>
  <span>
    <b-link
      v-if="plus"
      v-b-popover.hover.bottom.window="'prihlásiť sa'"
      class="tiny-button"
      @click="$emit('plus')"
    >
      <font-awesome-icon :icon="['fas', 'plus']" />
    </b-link>
    <b-link
      v-else
      v-b-popover.hover.bottom.window="'odhlásiť sa'"
      class="tiny-button"
      @click="$emit('minus')"
    >
      <font-awesome-icon :icon="['fas', 'minus']" />
    </b-link>
  </span>
</template>

<script>
export default {
  name: "PlusMinusButton",
  props: {
    plus: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
.tiny-button {
  color: gray;
  font-size: 0.8em;
}
</style>
