<template>
  <div>
    <b-container class="mt-5" fluid>
      <h1>Prihlasovanie na opravovanie</h1>
    </b-container>
    <LoadingSpinner :is-loaded="roundId !== null">
      <EvaluatorsDetail :round-id="roundId" />
    </LoadingSpinner>
    <LoadingSpinner v-if="roundId2" :is-loaded="roundId2 !== null">
      <EvaluatorsDetail :round-id="roundId2" />
    </LoadingSpinner>
  </div>
</template>

<script>
import { apiRounds, LoadingSpinner, utils } from "frontend-common";
import constants from "@/constants";
import contestGroupFilter from "@/mixins/contestGroupFilter";
import EvaluatorsDetail from "@/components/EvaluatorsDetail.vue";

export default {
  name: "Evaluators",
  components: {
    EvaluatorsDetail,
    LoadingSpinner,
  },
  mixins: [apiRounds, contestGroupFilter],
  props: {
    roundId: {
      type: Number,
      default: null,
    },
    roundId2: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {
    roundId() {
      this.loadRoundId();
    },
  },
  mounted() {
    this.loadRoundId();
  },
  methods: {
    currentRound(rounds, group) {
      const groupRounds = this.contestGroupFilterBy(
        rounds,
        "contest.group",
        group,
      );
      const groupRoundsFuture = groupRounds.filter(
        (round) => new Date(round.end_time) > utils.daysAgo(7),
      );
      if (groupRoundsFuture.length !== 0)
        return groupRoundsFuture[groupRoundsFuture.length - 1];
      return groupRounds[0];
    },
    async loadRoundId() {
      if (this.roundId !== null) return;
      const rounds = await this.apiRoundsLatest();
      this.$router.replace({
        name: "evaluators2",
        params: {
          roundId: this.currentRound(rounds, constants.contestGroups.PIKOMAT)
            .id,
          roundId2: this.currentRound(rounds, constants.contestGroups.PIKOFYZ)
            .id,
        },
      });
    },
  },
};
</script>

<style scoped></style>
